import Head from 'next/head';
import Link from 'next/link';
import BrandLayout from '../components/brand/BrandLayout';

export default function NotFound() {
  return (
    <BrandLayout>
      <Head>
        <title>ICTLIFE Merchant</title>
      </Head>
      <div className="offline">
        <div className="holder">
          <div className="icon">404: Page not found</div>
          <div>
            <div className="title">
              This is not the page you are looking for
            </div>
            <div className="description">
              <Link href="/">
                <a>Take me home</a>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BrandLayout>
  );
}
